<template>
    <div class="wrapper-how-we-selected justify-content-between container mt-5">
        <img src="../../assets/img/otbor.svg" class="mobile_img" alt="" width="280px" height="202px">
        <div class="wrapper-text">
            <h2 class="title">
                Как мы отбираем проекты,<br/>
                в которые вы инвестируете?
            </h2>
            <div class="small-title">
                «Рантье. Развиваем города» – это платформа коллективного финансирования в проекты, которые помогают городам становится умными, креативными и процветающими.
            </div>
        </div>
        <img src="../../assets/img/otbor.svg" class="dekstop_img" alt="">
    </div>
</template>